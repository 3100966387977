require("chart.js");
var Loader = require("../components/loader");
var NovoRequestFactory = require("../novo_request_factory");

function ChartUtil() {

    this.init = function () {

        _startChart();

        $(document).on("chart-refresh", _refreshChart);
        $(document).on("click", ".js__chart-filters-btn", _refreshChart);
        $(document).on("click", ".js__chart-export", _exportChart);
    };

    var _startChart = function () {

        _canvas = document.getElementById("js__chart-canvas");

        if (_canvas === null)
            return;

        _getData();
    };

    var _getData = function (download = false) {

        var data = {
            dateFrom: $(".js__chart-filters-date-from").val(),
            dateTo: $(".js__chart-filters-date-to").val(),
            supplierCode: $(".js__chart-filters-supplier").selectpicker("val"),
            office: $(".js__chart-filters-office").selectpicker("val")
        };

        if (download)
            data.download = true;

        $.ajax({
            method: "GET",
            url: "/linestops/stats/data",
            data: data,
            success: function (response) {

                if (download) {
                    response = JSON.parse(response);

                    /*
                    * 1. Add temp link to DOM;
                    * 2. Click it automatically to download file behind it;
                    * 3. Remove temp link from DOM.
                    */
                    if (navigator.msSaveBlob) { // If IE
                        var blob = novoUtilities.base64ToBlob(response.payload.file.split("base64,")[1], _excelFileType, 512);
                        navigator.msSaveBlob(blob, response.payload.filename);
                    } else {
                        var $link = $("<a>");
                        $link.attr("href", response.payload.file);
                        $("body").append($link);
                        $link.attr({
                            "href": response.payload.file,
                            "download": response.payload.filename
                        });
                        $link.get(0).click();
                        $link.remove();
                    }
                    return;
                }

                var chartData = JSON.parse(response);

                var dsIndex = 0;
                chartData.datasets.forEach(function (dataset) {
                    var keys = Object.keys(_chartColors);
                    var color = _chartColors[keys[dsIndex % keys.length]];
                    dataset.backgroundColor = color;
                    dsIndex++;
                });

                var ctx = _canvas.getContext("2d");

                if (_myChart !== null) {
                    _myChart.data.labels = chartData.labels;
                    _myChart.data.datasets = chartData.datasets;
                    _myChart.update();
                    return;
                }

                _myChart = new Chart(ctx, {
                    type: "bar",
                    data: chartData,
                    options: {
                        responsive: true,
                        legend: {
                            position: "top",
                        },
                        title: {
                            display: true,
                            text: "Open Linestops over time"
                        }
                    }
                });
            },
            error: function () {
                alert("An error occured! Could not fetch Chart data.");
            }
        });
    };

    var _refreshChart = function () {
        _getData();
    };

    var _exportChart = function () {
        _getData(true);
    };

    var _canvas = null;
    var _myChart = null;

    var _chartColors = {
        red: "rgb(255, 99, 132)",
        orange: "rgb(255, 159, 64)",
        green: "rgb(75, 255, 172)",
        purple: "rgb(153, 102, 255)",
        yellow: "rgb(255, 255, 100)",
        blue: "rgb(54, 162, 235)",
        grey: "rgb(201, 203, 207)"
    };

}

var cu = new ChartUtil();
cu.init();
module.exports = cu;