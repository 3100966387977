var Loader = require("./loader");
var Dropdown = require("./dropdown");
var NovoRequestFactory = require("../novo_request_factory");

function NovoTable() {

    var _novoTableCore = new _NovoTableCore();

    this.init = () => {

        $(document).ready(function () {
            let $wrapper = $("#js__novo-table-wrapper");
            if ($wrapper.length > 0) {
                _novoTableCore.init($wrapper);
                if ($(".js__novo-table-filters").length > 0)
                    _novoTableCore.applyFilters($(".js__novo-table-filters"));
                _novoTableCore.search();
            }
        });

        // Sort action
        $(document).on("click", ".js__table-sorting", function () {
            var $this = $(this),
                order = "DESC",
                $wrapper = $this.closest("#js__novo-table-wrapper");

            // Remove previous sorted table columns.
            let sortedColumns = $wrapper.find(".js__table-sorting.table-sorted").removeClass("table-sorted");
            // Add sorted for this clicked table column.
            $this.addClass("table-sorted");


            // Toggle sort if re-clicked on the sorting column.
            if ($this.data("sort-order") === "DESC" || $this.data("sort-order") == undefined)
                order = "ASC";
            $this.data("sort-order", order);

            _novoTableCore.init($wrapper);
            _novoTableCore.search();

        });

        // Pagination 
        $(document).on("click", ".js__novo-table-pagination li a", function () {
            var $this = $(this),
                $wrapper = $this.closest("#js__novo-table-wrapper"),
                $pageItem = $this.parent("li"),
                pageNumber = parseInt($this.html());

            if ($pageItem.hasClass("disabled") || $pageItem.hasClass("active"))
                return;

            _novoTableCore.init($wrapper);

            if ($this.hasClass("next"))
                _novoTableCore.nextPage();

            if ($this.hasClass("prev"))
                _novoTableCore.previousPage();

            if (pageNumber)
                _novoTableCore.setPage(pageNumber);

            _novoTableCore.search();
        });

        // Search action
        $(document).on("click", ".js__btn-table-search", function () {

            var $this = $(this),
                $parentForm = $this.closest(".js__novo-table-filters");

            if ($parentForm.data("target-table").length > 0) {

                let $wrapper = $($parentForm.data("target-table"));

                _novoTableCore.init($wrapper);
                _novoTableCore.applyFilters($parentForm);
                _novoTableCore.search();
            }

        });

        // Handle submit event of filters form
        $(document).on("submit", ".js__novo-table-filters", function () {
            var $form = $(this);
            if ($form.data("target-table").length > 0) {
                let $wrapper = $($form.data("target-table"));
                _novoTableCore.init($wrapper);
                _novoTableCore.applyFilters($form);
                _novoTableCore.search();
            }
            return false; // prevent submit
        });

        // Reset action
        $(document).on("click", ".js__btn-table-filters-reset", function () {
            this.form.reset();
            let $wrapper = $(this).closest(".js__novo-table-filters");
            Dropdown.resetDropdown();
            _novoTableCore.reset($wrapper);
        });

        /*
        * Novo table actions (exports etc)
        */
        $(document).on("click", ".js__novo-table-action", function () {
            var actionType = $(this).data("action-type");
            _novoTableCore.tableAction(actionType);
        });
    };


    function _NovoTableCore() {

        var _method = "GET";
        var _$wrapper = false;
        var _searchUrl = false;
        var _sortingColumns = [];
        var _filters = {};
        var _page = 1;
        var _excelFileType = "application/vnd.ms-excel;";


        // init table on wrapper element
        this.init = ($wrapper) => {
            var searchUrl = $wrapper.data("search-url");

            if ((typeof searchUrl) == "undefined") {
                novoDebugger.consoleError("Missing search URL data property (data-search-url).");
                return;
            }
            // Set table wrapper
            _$wrapper = $wrapper;
            // Set table search URL
            _searchUrl = searchUrl;

            // Set sort columns (works only for one now. But supports multiple)
            let sortedColumns = _$wrapper.find(".js__table-sorting.table-sorted");
            if (sortedColumns.length > 0) {
                $.each(sortedColumns, function (key, column) {
                    let fieldName = $(column).data("sort-field");
                    let sort = $(column).data("sort-order");
                    if ((typeof sort) != "undefined" && (typeof fieldName) != "undefined") {
                        _sortingColumns = [fieldName, sort];
                    }
                });
            }

            // Check active page for pagination
            let activePage = _$wrapper.find(".js__novo-table-pagination li.active a").first();
            if (activePage.length > 0)
                _page = parseInt($(activePage).html());

            // set filters and rest ?

        };

        this.reset = ($wrapper) => {
            // Form data for passing search params.
            _sortingColumns = [];
            // Override filters with only hidden inputs
            _filters = $wrapper.find(".js__filter-input-hidden").serializeArray().reduce(function (a, x) {
                /* 
                 * Note: it could happen that there are multiple hidden inputs with the same name (must be like that, we won't argue)
                 * In order to keep multiple values of the same input, we will create an array of it's values
                 */
                if (x.value) {
                    if (a[x.name]) {
                        if (Array.isArray(a[x.name])) {
                            a[x.name].push(x.value);
                        } else {
                            a[x.name] = [a[x.name], x.value];
                        }
                    } else {
                        a[x.name] = x.value;
                    }
                }
                return a;
            }, {});
            _page = 1;

            this.search();
        };


        this.search = () => {
            // form data for passing search params.
            var searchData = _prepareQueryParameters();

            NovoRequestFactory.setURL(_searchUrl + "?" + searchData.toString());
            NovoRequestFactory.setMethod(_method);
            NovoRequestFactory.setBeforeSendCallback(function () {
                Loader.show();
            });
            NovoRequestFactory.setSuccessCallback(function (response) {
                _$wrapper.html(response);
                Loader.hide();
            });
            NovoRequestFactory.setErrorCallback(function (response) {
                alert("XHR error in Mod NovoTable! \n" + response.responseText);
                novoDebugger.consoleError("XHR error in Mod NovoTable!");
                Loader.hide();
            });
            NovoRequestFactory.makeRequest();
        };


        this.tableAction = (actionType) => {
            var searchData = _prepareQueryParameters();
            
            NovoRequestFactory.setURL(_searchUrl + "/table-actions/" + actionType + "?" + searchData.toString());
            NovoRequestFactory.setMethod(_method);
            NovoRequestFactory.setBeforeSendCallback(function () {
                Loader.show();
            });
            NovoRequestFactory.setSuccessCallback(function (response) {
                response = JSON.parse(response);

                /*
                * 1. Add temp link to DOM;
                * 2. Click it automatically to download file behind it;
                * 3. Remove temp link from DOM.
                */
                if (navigator.msSaveBlob) { // If IE
                    var blob = novoUtilities.base64ToBlob(response.payload.file.split("base64,")[1], _excelFileType, 512);
                    navigator.msSaveBlob(blob, response.payload.filename);
                } else {
                    var $link = $("<a>");
                    $link.attr("href", response.payload.file);
                    $("body").append($link);
                    $link.attr({
                        "href": response.payload.file,
                        "download": response.payload.filename
                    });
                    $link.get(0).click();
                    $link.remove();
                }

                Loader.hide();
            });
            NovoRequestFactory.setErrorCallback(function (response) {
                alert("XHR error in Mod NovoTable! \n" + response.responseText);
                novoDebugger.consoleError("XHR error in Mod NovoTable!");
                Loader.hide();
            });
            NovoRequestFactory.makeRequest();
        };

         var _prepareQueryParameters = () => {
            let searchData = new URLSearchParams();

            // Append Sort
            if (_sortingColumns.length > 0) {
                searchData.append("sort", JSON.stringify(_sortingColumns));
            }
            // Append Page to be disaplyed
            searchData.append("page", _page);

            if (!$.isEmptyObject(_filters)) {
                searchData.append("filters", JSON.stringify(_filters));
            }

            return searchData;
        };

        this.applyFilters = ($form) => {
            // filters form.
            // will do only search for now.

            // Textsearch
            var $search = $form.find("#js__filter-search");
            if ($search.length > 0) {
                this.addFilter("search", $search.val());
            }
            
            _filters = $form.serializeArray().reduce(function (a, x) {
                if (x.value) {
                    if (a[x.name]) {
                        /*
                         * Format data for multiselect as array
                        */
                        if ($.isArray((a[x.name]))) {
                            a[x.name].push(x.value);
                        } else {
                            var oldValue = a[x.name],
                                newValue = x.value;
                            a[x.name] = [];
                            a[x.name].push(oldValue);
                            a[x.name].push(newValue);
                        }
                    } else {
                        a[x.name] = x.value;
                    }
                }
                return a;
            }, {});
        };

        // Todo for filters etc.
        this.setPage = (page) => {
            _page = page;
        };

        this.nextPage = () => {
            _page = _page + 1;
        };
        this.previousPage = () => {
            _page = Math.max(_page - 1, 1);
        };

        this.addSort = (fieldName, sort) => {
            // Set sort columns (works only for one now)
            // If we need multiple. Add to array instead of seting one
            _sortingColumns = [fieldName, sort];
        };

        this.addFilter = (fieldName, value) => {
            _filters[fieldName] = value;
        };

        this.setMethod = (value) => {
            _method = value;

        };

        this.setWrapper = (value) => {
            _$wrapper = value;
        };

        this.setSearchUrl = (value) => {
            _searchUrl = value;
        };

    }

}

var novoTable = new NovoTable();
novoTable.init();
module.exports = novoTable;