$(".js__datepicker").datepicker({
    format: "dd.mm.yyyy",
    //startDate: '0d'// from today
}).on("show", function (e) {

    // hackish prevention of datepicker getting out of body bounds (left side)
    var datepicker = document.querySelector(".datepicker");
    if (+datepicker.style.left.replace("px", "") < 0) {
        $(datepicker).css("left", "0");
    }
}).on("change", function (e) {
 
});