function BtnConfirmDelete() {

    var $modal_ = $("#js__modal-confirm-delete");

    this.init = function () {

        $(document).on("click", ".js__modal-confirm-delete", function () {
            let confirm = "Are you sure? This action is final!";
            let $this = $(this),
                entity = $this.data("entity"),
                recordId = $this.data("record-id"),
                confirmText = $this.data("confirm-text");

            if (confirmText) confirm = confirmText;
            
            let formAction = `/api/${entity}/${recordId}`;

            $modal_.find("form").attr("action", formAction)
            $modal_.find("#js__confirm_delete_text").html(confirm);

            $modal_.modal("show");
        });

    };

}

var bcd = new BtnConfirmDelete();
bcd.init();
module.exports = bcd;