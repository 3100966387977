var NovoForm = require("../components/novo_form");
var Loader = require("./loader");

function BtnRejectLinestop() {

    var $modalReject_ = $("#js__modal-reject");
    var $modalPushback_ = $("#js__modal-pushback");
    var $modalApprove_ = $("#js__modal-approve");

    this.init = function () {

        $(document).on("click", ".js__btn-reject-lst", function () {
            var action = $(this).data("action");
            if (action) {
                $modalReject_.find("form").attr("action", action); // update form action based on lst clicked
            }
            $modalReject_.modal("show");
        });

        $(document).on("click", ".js__btn-pushback-lst", function () {
            var action = $(this).data("action");
            if (action) {
                $modalPushback_.find("form").attr("action", action);
            }
            $modalPushback_.modal("show");
        });

        $(document).on("click", ".js__btn-approve-lst", function () {
            // Need to update Linestop before approved.
            let linestopForm = $("form.js__novo-form.linestops-editor-form").get(0);
            if (linestopForm) {
                let updated = updateLinestopData(linestopForm);
                if (updated) {
                    $modalApprove_.modal("show");
                }
                return;
            }
            // else 
            var action = $(this).data("action");
            if (action) {
                $modalApprove_.find("form").attr("action", action);
            }
            $modalApprove_.modal("show");
        });

        var updateLinestopData = function (linestopForm) {
            Loader.show();
            let submitLinestop = NovoForm.novoSynchronousRequest(linestopForm);
            Loader.hide();
            return submitLinestop;
        }
    };


}

var brl = new BtnRejectLinestop();
brl.init();
module.exports = brl;