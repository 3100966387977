function Dropdown() {

    this.init = () => {
        $(".js__selectpicker").selectpicker({
            liveSearch: true,
            liveSearchPlaceholder: "Start typing to search...",
            liveSearchNormalize: true
        });
    };
    
    this.resetDropdown = ($dropdown) => {
        var $dropdown = (arguments.length) === 0 ? $(".js__selectpicker") : $dropdown;
        $dropdown.val("default");
        $dropdown.selectpicker("refresh");
    };

    this.init();
}

module.exports = new Dropdown();