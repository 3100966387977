function BtnLog() {

    var $modal_ = $("#js__modal-changesets");

    this.init = function () {
        $(document).on("click", ".js__logs-info-modal", _fetchChangesets);
    };

    var _fetchChangesets = function () {
        let $this = $(this),
            entity = $this.data("entity"),
            recordId = $this.data("record-id");

        $.ajax({
            url: "/linestops/" + recordId + "/changesets",
            method: "GET",
            success: function (response) {
                response = JSON.parse(response);
                $modal_.find(".modal-body").html(response.meta.html);
                $modal_.modal("show");
            }
        });
    };
}

var btnlog = new BtnLog();
btnlog.init();
module.exports = btnlog;