var Loader = require("./loader");
var NovoRequestFactory = require("../novo_request_factory");

function NovoForm() {
    
    var $_form = null;

    this.init = () => {

        $(document).on("click", ".js__novo-form button[type=submit]", function () {
            $(".js__novo-form button[type=submit]").removeClass("focused");
            $(this).addClass("focused");
        });

        // Disable form submit on enter press
        $(".js__novo-form").on("keyup keypress", function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
        });

        $(".js__novo-form").on("submit", function (e) {
            $_form = $(e.target);
            var formControl = $_form.find("button[type=submit].focused").get(0);
            var validationErrorsCount = _validate();

            var payload = $_form.serialize();
            payload += "&" + formControl.name + "=" + formControl.value;
            
            if (validationErrorsCount > 0)
                return false;

            NovoRequestFactory.setURL($_form.attr("action"));
            NovoRequestFactory.setMethod($_form.data("http-method"));
            NovoRequestFactory.setData(payload);
            NovoRequestFactory.setBeforeSendCallback(_beforeSendCallback);
            NovoRequestFactory.setSuccessCallback(_successCallback);
            NovoRequestFactory.setErrorCallback(_errorCallback);
            NovoRequestFactory.makeRequest();

            // return false to prevent form submit
            return false;
        });
        
    };

    var _beforeSendCallback = function () {
        Loader.show();
    };

    var _successCallback = function (response) {
        response = JSON.parse(response);
        if (response.status === true) {
            alert(response.payload.message != null ? response.payload.message : "Action completed!");
            if (response.payload.redirect) {
                // if redirect is set to refresh. Reload the same page
                if (response.payload.redirect === "refresh") {
                    window.location = window.location;
                    return;
                }
                window.location.href = response.payload.redirect;
                return;
            }
        } else {
            alert("Error! Please refer to log for more info:\n" + response.payload.data.join("\n"));
            novoDebugger.consoleError("Error! See errors below:");
            novoDebugger.consoleLog(response.payload.data);
        }
        Loader.hide();
    };

    var _errorCallback = function (response) {
        response = JSON.parse(response.responseText);
        alert("XHR error in Mod NovoForm! \n" + response.payload.message);
        novoDebugger.consoleError("XHR error in Mod NovoForm!");
        Loader.hide();
    };

    this.novoSynchronousRequest = (form) => {
        var success = true;
        $_form = $(form);
        var formControl = $_form.find("button[type=submit]").get(0);
        var validationErrorsCount = _validate();

        if (validationErrorsCount > 0)
            return false;

        var payload = $_form.serialize();
        payload += "&" + formControl.name + "=" + formControl.value;

        NovoRequestFactory.setURL($_form.attr("action"));
        NovoRequestFactory.setIsAsync(false);
        NovoRequestFactory.setMethod($_form.data("http-method"));
        NovoRequestFactory.setData(payload);
        NovoRequestFactory.setBeforeSendCallback(_beforeSendCallback);
        NovoRequestFactory.setSuccessCallback(function () {
            success = true;
            return success;
        });
        NovoRequestFactory.setErrorCallback(function () {
            success = false;
            return success;
        });
        NovoRequestFactory.makeRequest();

        return success;
    }


    var _validate = function () {

        var $inputs = $_form.find("input, select, textarea")
            .not(":input[type=button], :input[type=submit], :input[type=reset], :input[type=search]");

        // may also be a third party validation library if needed
        var validator = require("../util/vito_validator");

        var overallErrorsCount = 0;

        $inputs.each(function (i, input) {
            var $input = $(input),
                $wrapper = $input.closest(".form-group");

            var validationStatus = validator.validate($input);

            $wrapper.removeClass("validation-error");
            $wrapper.find(".js__novo-validation-errors").remove();
            if (validationStatus.status === false) {
                validationStatus.payload.forEach(function (validationError) {
                    $wrapper.addClass("validation-error");
                    $wrapper.append("<div class='js__novo-validation-errors error-text'>^" + validationError.description + "</div>");
                });
                overallErrorsCount++;
            }

        });

        novoDebugger.consoleWarn("NOVO FORM: Overall Errors Count: " + overallErrorsCount);
        return overallErrorsCount;
    };

}

var nf = new NovoForm();
nf.init();
module.exports = nf;