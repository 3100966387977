function NoSubmitForm() {

    this.init = () => {
        $(".js__no-submit-form").on("submit", function (e) {
            return false;
        });
    };

}

var nsf = new NoSubmitForm();
nsf.init();
module.exports = nsf;