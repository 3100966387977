var InputAutocomplete = require("./input_autocomplete");

function SupplierCode() {

    this.init = function () {

        /*
        * This Module injects a custom postprocessor to a InputAutocomplete Module,
        * not disrupting and not interfering with the base functionality of origin Module.
        */

        var processor = function ($input, label, value, parent) {

            var partData = parent;

            if ($input.closest(".js__mod-device-scode").length === 0)
                return false;

            var $inputWrapper = $input.closest(".form-group");

            var $nextInputWrapper = $inputWrapper.nextAll(".form-group").first(),
                $boundInput = $nextInputWrapper.find(".js__mod-device-scode-code > input");
            $boundInput.val(partData.mfr);

            var $secondInputWrapper = $inputWrapper.nextAll(".form-group:eq(1)"),
                $boundInput = $secondInputWrapper.find(".js__mod-device-vcode-code > input");
            $boundInput.val(partData.vendor);
        };

        InputAutocomplete.addAutocompletePostprocess(processor);

    };

}

var sc = new SupplierCode();
sc.init();
module.exports = sc;