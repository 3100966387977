var InputAutocomplete = require("./input_autocomplete");

function CustomerNumber() {

    this.init = function () {

        /*
        * This Module injects a custom postprocessor to a InputAutocomplete Module,
        * not disrupting and not interfering with the base functionality of origin Module.
        */

        var processor = function ($input, label, value, parent) {

            if ($input.closest(".js__mod-customer-number").length === 0)
                return false;

            var $inputWrapper = $input.closest(".form-group"),
                $nextInputWrapper = $inputWrapper.nextAll(".form-group").first(),
                $boundInput = $nextInputWrapper.find("input").first();
            $boundInput.val(novoUtilities.decodeHTMLEntities(label));
        };

        InputAutocomplete.addAutocompletePostprocess(processor);

    };

}

var cn = new CustomerNumber();
cn.init();
module.exports = cn;