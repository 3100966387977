var Loader = require("../components/loader");

function AddNewInputType() {
    
    this.init = () => {

        var entity, // name of complex type (value: recipients)
            $wrapper = $(".js__input-type-wrapper"),
            $addButton = $(".js__add-new-input-type"), // Add New button
            x = 1000; // hardcoded. used only for vito-name indexing and alert placement.

        $addButton.click(function (e) { // on add new button click
            e.preventDefault();
            Loader.show();

            var $this = $(this),
                entity = $this.attr("id").split("-").pop(), // gets complex type (`recipients`) based on button id 
                type = $this.data("input-type"), // gets input type (e.g. `email`)
                name = $this.data("input-name"), // gets input name (e.g. `Email`)
                placeholder = $this.data("placeholder");

            var html = "<div class='form-group d-flex align-items-center'>";
            html += "<div class='w-50'>";
            html += `<input type='${type}' class='form-control' name='${name}[]' vito-name='${name}-${x}' placeholder='${ placeholder }'>`;
            html += "</div>";
            html += "<a href='javascript:;' class='btn btn-sm ml-2 text-primary js__input-type-remove-line'>";
            html += "<span class='fas fa-trash-alt'></span><span>Delete</span>";
            html += "</a>";
            html += "</div>";

            x++; // row increment
            $this.parents("#js__input-type-" + entity).append(html);
            Loader.hide();
        });

        $(".js__input-type-wrapper").on("click", ".js__input-type-remove-line", function (e) { // remove btn
            e.preventDefault();
            $(this).closest(".form-group").remove();
        });

    }

    this.init();
}

module.exports = new AddNewInputType();