require("../css/style.scss");

require("jquery");
require("bootstrap");
require('url-search-params-polyfill');
require("bootstrap-select");
require("./vendor/sb-admin-2");
require("./prototype");
require("./novo_debugger");
require("./novo_utilities");

require("./vendor/bootstrap-datepicker");

require("./util/complex_type");
require("./util/add_new_input_type");
require("./util/chart");
require("./components/input_autocomplete");

require("./components/datepicker");
require("./components/dropdown");
require("./components/customer_number");
require("./components/supplier_code");
require("./components/no_submit_form");
require("./components/novo_table");
require("./components/novo_form");
require("./components/chat_form");
require("./components/btn_actions_linestop");
require("./components/btn_confirm_delete");
require("./components/btn_log");
require("./components/rte");
require("./components/modal_resolve")