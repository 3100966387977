function Loader() {
    var $loader = $("#page-loader");

    this.show = () => {
        $loader.show();
    };

    this.hide = () => {
        $loader.hide();
    };

    // Default init class.
    $(document).on("click", ".js__page-loader-init", function () {
        $loader.show();
    });
}

var L = new Loader();
module.exports = L;