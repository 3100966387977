function VitoValidator() {

    this.init = function () {
        $.fn.vitoProperties = function () {
            var $element = $(this),
                attributes = $element[0].attributes,
                vitoProperties = [];
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].nodeName.indexOf("vito-") === 0) {
                    vitoProperties.push(attributes[i].nodeName.substring(5));
                }
            }
            return vitoProperties;
        };
    };

    this.validate = function ($input) {

        var inputErrors = [];

        $input.vitoProperties().forEach(function (validationType) {

            if (validationType === "name")
                return;

            var validateValue = $input.val();
            var validateAgainst = $input.attr("vito-" + validationType);

            switch (validationType) {

                case "mandatory":
                    if (validateAgainst == true && !_validateMandatory(validateValue))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `mandatory`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "mandatory-no-zero":
                    if (validateAgainst == true && !_validateMandatoryNoZero(validateValue))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `mandatory-no-zero`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "min-value":
                    if (!_validateMinValue(validateValue, validateAgainst))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `min-value`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "max-value":
                    if (!_validateMaxValue(validateValue, validateAgainst))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `max-value`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "min-length":
                    if (!_validateMinLength(validateValue, validateAgainst))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `min-length`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "max-length":
                    if (!_validateMaxLength(validateValue, validateAgainst))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `max-length`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "in":
                    if (!_validateIn(validateValue, validateAgainst))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `in`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "url":
                    if (validateAgainst == true && !_validateURL(validateValue))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `url`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                case "email":
                    if (validateAgainst == true && !_validateEmail(validateValue))
                        inputErrors.push({
                            validatedValue: validateValue,
                            validatedAgainst: validateAgainst,
                            description: "Criteria not matched: `email`",
                            origin: $input.attr("vito-name")
                        });
                    break;

                default: break;
            }

        });

        return {
            status: inputErrors.length === 0,
            payload: inputErrors
        };
    };

    var _validateMinLength = function (inputValue, criteriaValue) {
        return inputValue.replace(/\n/g, "\r\n").length >= parseInt(criteriaValue);
    };

    var _validateMaxLength = function (inputValue, criteriaValue) {
        return inputValue.replace(/\n/g, "\r\n").length <= parseInt(criteriaValue);
    };

    var _validateMinLengthCleartext = function (inputValue, criteriaValue) {
        return inputValue.replace(/(<([^>]+)>)/ig, "").replace(/&(.*?);/ig, " ").length >= parseInt(criteriaValue);
    };

    var _validateMaxLengthCleartext = function (inputValue, criteriaValue) {
        return inputValue.replace(/(<([^>]+)>)/ig, "").replace(/&(.*?);/ig, " ").length <= parseInt(criteriaValue);
    };

    var _validateMinValue = function (inputValue, criteriaValue) {
        return inputValue.isNumber() && parseFloat(inputValue) >= parseFloat(criteriaValue);
    };

    var _validateMaxValue = function (inputValue, criteriaValue) {
        return inputValue.isNumber() && parseFloat(inputValue) <= parseFloat(criteriaValue);
    };

    var _validateNumeric = function (inputValue, criteriaValue) {
        return inputValue.isNumber();
    };

    var _validateIn = function (inputValue, criteriaValue) {
        return criteriaValue.split("|").indexOf(inputValue) >= 0;
    };

    var _validateMandatory = function (inputValue) {
        return inputValue != "";
    };

    var _validateMandatoryNoZero = function (inputValue) {
        return inputValue != "" && inputValue != "0";
    };

    var _validateEmail = function (inputValue) {
        return inputValue == "" || inputValue.isEmail();
    };

    var _validateURL = function (inputValue) {
        return inputValue == "" || inputValue.isUurl();
    };

};


var vv = new VitoValidator();
vv.init();
module.exports = vv;