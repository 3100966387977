function NovoUtilities() {

    this.decodeHTMLEntities = function (string) {
        var entities = [
            ["amp", "&"],
            ["apos", "\""],
            ["#x27", "\""],
            ["#x2F", "/"],
            ["#39", "\""],
            ["#47", "/"],
            ["lt", "<"],
            ["gt", ">"],
            ["nbsp", " "],
            ["quot", "\""]
        ];

        for (var i = 0, max = entities.length; i < max; ++i) 
            string = string.replace(new RegExp("&" + entities[i][0] + ";", "g"), entities[i][1]);

        return string;
    };

    this.base64ToBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });

        return blob;
    };

}

var novoUtilities = new NovoUtilities();
// make novoUtilities a global object
global.novoUtilities = novoUtilities;