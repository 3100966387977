var tinymce = require("tinymce/tinymce.min.js");
require("tinymce/themes/silver/theme.min.js");
require("tinymce/plugins/paste");
require("tinymce/plugins/link");
require("tinymce/plugins/lists");
require("tinymce/plugins/code");

function TinyMce() {
    this.init = () => {
        tinymce.init({
            selector: ".js__rte-text",
            skin: false,
            height: 500,
            content_css: false,
            menubar: false,
            branding: false,
            plugins: ["paste", "link", "lists", "code"],
            paste_as_text: true,
            toolbar: "undo redo | bold italic underline superscript subscript| link | numlist bullist | code"
        });
    }
}

var tmce = new TinyMce();
tmce.init();
module.exports = tmce;