var Loader = require("./loader");
var NovoRequestFactory = require("../novo_request_factory");

function ChatForm() {

    this.init = () => {

        $(".js__message-list").each(function (index, val) {
            scrollToBottom.bind($(val)).call();
        })

        // Copy a message to Linestop History
        $(".js__copy-chat").on("click", function (e) {
            let bubble = $(this).closest(".bubble"),
                message = bubble.find("p").first().html();

            // Add it to the Linestop history chat.
            let $linestopHistory = $("#js__linestops_form_entry");
            $linestopHistory.val($linestopHistory.val() + message + "\n");
            $linestopHistory.focus();
        });

        $(".js__chat-submit-button").on("click", function (e) {
            e.preventDefault();
            let $this = $(this),
                $form = $this.closest(".js__chat-form"),
                submitValue = $this.val();
            // update the form hidden input.
            $form.find(`input[name="submit_action"]`).val(submitValue);
            // submit the form (submit event handles the rest.)
            $form.submit();

        });

        //scrollToBottom();
        $(".js__chat-form").on("submit", function (e) {
            var $form = $(e.target);
            var $chatWrapper = $form.closest(".js__chat_wrapper");
            var $messageContainer = $chatWrapper.find(".js__message-list");
            var $textMessage = $form.find("textarea[name='Comment']");

            if ($textMessage.val() == "" || $textMessage.val().length == 0) {
                return false;
            }

            NovoRequestFactory.setURL($form.attr("action"));
            NovoRequestFactory.setMethod($form.data("http-method"));
            NovoRequestFactory.setData($form.serialize());
            NovoRequestFactory.setBeforeSendCallback(function () {
                Loader.show();
            });
            NovoRequestFactory.setSuccessCallback(function (response) {
                response = JSON.parse(response);
                // Update chat bubbles. 
                if (response.status === true) {
                    $textMessage.val("");
                    let comment = response.payload.data;
                    $messageContainer.append(messageChatBubble(comment.message, comment.user, comment.time));
                    scrollToBottom.bind($messageContainer).call();

                    if (comment.refresh_page) {
                        location.reload();
                    }
                }
                else
                    ;// TODO novi modul koji ce prikazivati modal sa error porukama
                Loader.hide();
            });
            NovoRequestFactory.setErrorCallback(function (response) {
                alert("XHR error in Mod NovoForm! \n" + response.responseText);
                novoDebugger.consoleError("XHR error in Mod NovoForm!");
                Loader.hide();
            });
            NovoRequestFactory.makeRequest();

            return false;

        });

    };

    var messageChatBubble = function (message, user, time) {
        return "<div class='bubble me'><span class='author'><i>" + user + " on: " + time + "</i></span><p>" + message + "</p></div>";
    };

    var scrollToBottom = function () {
        if (this.length > 0) {
            this[0].scrollTop = this[0].scrollHeight;
        }
    };

}

var cf = new ChatForm();
cf.init();
module.exports = cf;