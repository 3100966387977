var Loader = require("../components/loader");
var NovoRequestFactory = require("../novo_request_factory");

function ComplexType() {
    
    this.init = () => {

        var entity, // name of complex type (value: recipients)
            $wrapper = $(".js__complex-type-wrapper"),
            $addButton = $(".js__add-new-complex-type"), // Add New button
            x = 1000; // hardcoded. used only for vito-name indexing and alert placement.

        $addButton.click(function (e) { // on add new button click
            e.preventDefault();

            var $this = $(this),
                entity = $this.attr("id").split("-").pop(), // gets complex type (`recipients`) based on button id 
                model = $this.data("model"); // gets model (e.g. `Linestop`)

            NovoRequestFactory.setURL("/complex-type");
            NovoRequestFactory.setMethod("POST");
            NovoRequestFactory.setData({
                type: entity,
                index: x,
                model: model
            });
            NovoRequestFactory.setBeforeSendCallback(function () {
                Loader.show();
            });
            NovoRequestFactory.setSuccessCallback(function (response) {
                x++; // row increment
                $this.parents("#js__complex-type-" + entity).append($(response).unwrap()); // removes surounding <section> from response
                Loader.hide();
            });
            NovoRequestFactory.setErrorCallback(function (response) {
                alert("XHR error in Mod ComplexType!");
                novoDebugger.consoleError("XHR error in Mod ComplexType!");
            });
            NovoRequestFactory.makeRequest();

        });

        $(".js__complex-type-wrapper").on("click", ".js__complex-type-remove-line", function (e) { // remove btn
            e.preventDefault();
            $(this).closest(".form-group").remove();
        });


        $wrapper.on("change", function () { // for links and files, not for devices!
            var $complexTypeWrapper = $(this),
                $complexTypeRow = $complexTypeWrapper.find(".form-group"),
                emptyInputsCount = 0;


            $complexTypeRow.each(function () {
                var $complexTypeInputs = $(this).find("input, select, textarea").not(":input[type=button], :input[type=submit], :input[type=reset]");

                // count empty inputs inside complex type
                for (var i = 0; i < $complexTypeInputs.length; i++) {
                    if ($($complexTypeInputs[i]).val() == "") {
                        emptyInputsCount++;
                    }
                }

                $complexTypeInputs.each(function () {
                    // if all fields inside complex type are empty, remove mandatory;
                    // else flag the field as mandatory
                    if ($complexTypeInputs.length == emptyInputsCount) {
                        $(this).removeAttr("vito-mandatory");
                    } else {
                        $(this).attr("vito-mandatory", 1);
                    }
                });
                emptyInputsCount = 0;
            });
        });

    }

    this.init();
}

module.exports = new ComplexType();