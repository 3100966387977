function ModalResolve() {

    this.init = function () {

        var $modal = $("#js__modal-resolve");

        if ($modal.length === 0)
            return;

        $(document).on("click", ".js__btn-modal-resolve", () => {
            $modal.modal("show");
        });

    };

}

var mr = new ModalResolve();
mr.init();
module.exports = mr;