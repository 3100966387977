var Loader = require("./loader");
var NovoRequestFactory = require("../novo_request_factory");

function InputAutocomplete() {

    /*
     * Defines after how many typed characters the search function would trigger.
     */
    var _triggerLength = 4;
    var _autocompletePostprocesses = [];
    var _timer; // Timer for user to finish input.

    this.init = () => {

        $(document).on("input", ".js__input-autocomplete", function () {
            var $input = $(this),
                value = $input.val(),
                searchUrl = $input.data("autocomplete-search"),
                $resultsWrapper = $input.next(".js__autocomplete-result"),
                backdropElement = "<div class='autocomplete-backdrop js__autocomplete-backdrop'></div>",
                $inputFormGroup = $input.closest(".form-group");

            $inputFormGroup.addClass("active");
            if ($(".autocomplete-backdrop").length == 0) {
                $("#wrapper").after(backdropElement);
            }
            var $backdrop = $(".autocomplete-backdrop");
            $backdrop.data("input-connected", $input.prop("name"));
            $backdrop.addClass("active");
            if (value.length == 0) {
                $inputFormGroup.removeClass("active");
                $backdrop.removeClass("active");
                $resultsWrapper.hide();

                if (_autocompletePostprocesses.length > 0)
                    _autocompletePostprocesses.forEach(function (acpp) {
                        acpp.call(this, $input, "", "", "");
                    });
            }

            if (value.length >= _triggerLength) {

                if (_timer) {
                    clearTimeout(_timer);
                }
                _timer = setTimeout(function () {
                    NovoRequestFactory.setURL(searchUrl);
                    NovoRequestFactory.setMethod("GET");
                    NovoRequestFactory.setData({ search: value });
                    NovoRequestFactory.setBeforeSendCallback(function () {
                        Loader.show();
                    });
                    NovoRequestFactory.setSuccessCallback(function (response) {
                        response = JSON.parse(response);

                        var listElements = "";
                        if (response.payload.data && response.payload.data.length > 0) {
                            for (let i = 0; i < response.payload.data.length; i++) {
                                listElements += "<li class='dropdown-item'>" +
                                    "<a href='javascript:;' data-input-value='" + response.payload.data[i].id + "' " +
                                    "data-input-parent='" + response.payload.data[i].parent + "'>" +
                                    response.payload.data[i].title +
                                    "</a></li>";
                            }
                        } else {
                            listElements = "<li class='dropdown-item'><a href='javascript:;'>No Results Found</a></li>";
                        }
                        $resultsWrapper.html(listElements);
                        $resultsWrapper.show();
                        Loader.hide();
                    });
                    NovoRequestFactory.setErrorCallback(function (response) {
                        alert("XHR error in Mod InputAutocomplete! \n" + response.responseText);
                        novoDebugger.consoleError("XHR error in Mod InputAutocomplete!");
                        Loader.hide();
                    });
                    NovoRequestFactory.makeRequest();
                }, 1000);
            }
        });

        $(document).on("click", ".js__autocomplete-result li a", function () {
            var $item = $(this),
                value = $item.data("input-value"),
                parent = $item.data("input-parent"),
                label = $item.html().trim(),
                $dropdown = $item.parents(".js__autocomplete-result"),
                $input = $dropdown.prev(".js__input-autocomplete"),
                $inputFormGroup = $input.closest(".form-group"),
                $activeBackdrop = $(".autocomplete-backdrop.active");

            $input.val(value);
            $dropdown.hide(); 

            if ($activeBackdrop.length) {
                $inputFormGroup.removeClass("active");
                $activeBackdrop.removeClass("active");
            }

            /*
             * If Postprocessor function is set from an external Module,
             * call it after the base functionality is complete
             */
            if (_autocompletePostprocesses.length > 0)
                _autocompletePostprocesses.forEach(function (acpp) {
                    acpp.call(this, $input, label, value, parent);
                });
        });

        /*
         * When backdrop for autocomplete is clicked,
         * empty the input field value and also connected field (if any, using postprocess)
         */
        $(document).on("click", ".js__autocomplete-backdrop", function () {
            var $backdrop = $(this),
                inputName = $backdrop.data("input-connected"),
                $input = $(`[name='${inputName}']`),
                $inputFormGroup = $input.closest(".form-group"),
                $resultsWrapper = $input.next(".js__autocomplete-result");

            $input.val("");
            $inputFormGroup.removeClass("active");
            $backdrop.removeClass("active");
            $resultsWrapper.hide();

            if (_autocompletePostprocesses.length > 0)
                _autocompletePostprocesses.forEach(function (acpp) {
                    acpp.call(this, $input, "", "", "");
                });
        });
    };

    this.addAutocompletePostprocess = function (processor) {
        if (typeof processor !== "function") {
            novoDebugger.consoleError("Attempted setting non-function as Callback in Mod InputAutocomplete");
            return;
        }

        if (processor.length !== 4) {
            novoDebugger.consoleError("Must use a 4-parameters Callback function ($input, label, value, parent) in Mod InputAutocomplete");
            return;
        }

        _autocompletePostprocesses.push(processor);
    };

}

var ia = new InputAutocomplete();
ia.init();
module.exports = ia;