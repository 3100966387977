function Debugger() {

    this.consoleLog = function (payload) {
        if (typeof payload === "object") {
            console.log(payload);
        } else {
            console.log("%c" + payload, "color: white; background: #284882;");
        }
    };

    this.consoleWarn = function (payload) {
        if (typeof payload === "object") {
            console.log(payload);
        } else {
            console.log("%c" + payload, "color: white; background: #d69047;");
        }
    };

    this.consoleError = function (payload) {
        if (typeof payload === "object") {
            console.log(payload);
        } else {
            console.log("%c" + payload, "color: white; background: #a51f1f;");
        }
    };
}

var d = new Debugger();
global.novoDebugger = d;