function NovoRequestFactory() {

    var _method = "POST";
    var _url = "/";
    var _isAsync = true;
    var _isJSONP = false;
    var _data = {};
    var _beforeSendCallback = function () { };
    var _successCallback = function () { };
    var _errorCallback = function () { };

    this.setMethod = function (method) {
        if (["get", "post", "put", "patch", "delete"].indexOf(method.toLowerCase()) === -1) {
            novoDebugger.consoleError("Invalid method in NovoRequestFactory setter. Rolling back to POST.");
            return;
        }
        _method = method;
    };

    this.setURL = function (url) {
        _url = url;
    };

    this.setIsAsync = function (isAsync) {
        _isAsync = !!isAsync;
    };

    this.setIsJSONP = function (isJSONP) {
        _isJSONP = !!isJSONP;
    };

    this.setData = function (data) {
        _data = data;
    };

    this.setBeforeSendCallback = function (processor) {
        if (typeof processor !== "function") {
            novoDebugger.consoleError("Attempted setting non-function as Callback in Mod NovoRequestFactory");
            return;
        }
        _beforeSendCallback = processor;
    };

    this.setSuccessCallback = function (processor) {
        if (typeof processor !== "function") {
            novoDebugger.consoleError("Attempted setting non-function as Callback in Mod NovoRequestFactory");
            return;
        }
        _successCallback = processor;
    };

    this.setErrorCallback = function (processor) {
        if (typeof processor !== "function") {
            novoDebugger.consoleError("Attempted setting non-function as Callback in Mod NovoRequestFactory");
            return;
        }
        _errorCallback = processor;
    };

    /*
    * Core function.
    * Use Jquery Ajax function, Axios, other 3rd party 
    * or custom XMLHttpRequest implementation to generate request.
    */
    this.makeRequest = function () {
        $.ajax({
            url: _url,
            method: _method,
            data: _data,

            beforeSend: _beforeSendCallback,
            success: _successCallback,
            error: function (response) {

                if (response.status === 401) {
                    /*
                    * Unauthorized = HTTP 401.
                    * ------------------------
                    * Happens commonly if User Session has expired.
                    * In these cases, Backend will do a proper redirect.
                    * If User is valid, redirect should start/reload/extend the Session.
                    */
                    alert("Your Session has expired. System will now redirect you to automatically reload your Session...");
                    window.location.href = "/";
                    return;
                }

                _errorCallback.call(this, response);
            }
        });
    };

}

var nrf = new NovoRequestFactory();
module.exports = nrf;